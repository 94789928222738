import Vue from 'vue'
import VueRouter from 'vue-router'
import register from '../views/register.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'register',
    component: register,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/success',
    name: 'success',
    meta: {
      title: "亿客宝"
    },
    component: () => import('../views/success.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    meta: {
      title: "亿客宝"
    },
    component: () => import('../views/policy.vue')
  },
  {
    path: '/sharelog',
    name: 'sharelog',
    meta: {
      title: "亿客宝"
    },
    component: () => import('../views/sharelog.vue')
  },
  {
    path: '/shareregister',
    name: 'shareregister',
    meta: {
      title: "亿客宝"
    },
    component: () => import('../views/shareregister.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router