const CryptoJS = require('crypto-js');

import dayjs from "dayjs"

const iv = CryptoJS.enc.Utf8.parse('LmOdja9trNiU2Sbg');
export function test() {
  console.log(iv)
}

export function getVerificode() {
  const dt_str = dayjs().format("YYYYMMDDHHmmss");
  return encode(dt_str);
}

export function encode(word) {
  // 十六位十六进制数作为密钥
  const dt_str = dayjs().format("YYYYMMDDYYYYMMDD");
  const key = CryptoJS.enc.Utf8.parse(dt_str);
  // 十六位十六进制数作为密钥偏移量
  const srcs = CryptoJS.enc.Utf8.parse(word);
  console.log("key", key);
  console.log("iv", iv);
  console.log("srcs", srcs);
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  console.log("encrypted", encrypted);
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}

export function decode(word) {
  const dt_str = dayjs().format("YYYYMMDDYYYYMMDD");
  const key = CryptoJS.enc.Utf8.parse(dt_str);
  const encryptedHexStr = CryptoJS.enc.Base64.parse(word);
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}